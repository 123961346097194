<template>
    <div class="blog-card">
        <div v-show="editPost" class="icons">
            <div class="icon">
                <Edit class="edit" />
            </div>
            <div class="icon">
                <Delete class="delete" />
            </div>
        </div>
        <router-link class="link" :to="{ name: 'Prints' }">
            <img :src="require(`../../assets/collections/${post.articlePhoto}.png`)" alt="">
        </router-link>
    </div>
</template>


<script>
import Edit from "../../assets/Icons/edit-regular.svg"
import Delete from "../../assets/Icons/trash-regular.svg"

export default {
    name: "Article-Preview-Component",
    props: ["post"],
    components: {
        Edit,
        Delete,
    },
    computed: {
        editPost() {
            return this.$store.state.editPost;
        }
    }
}
</script>


<style lang="scss" scoped>
.blog-card {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: white;
    transition: .5s ease all;

    &:hover {
        transform: scale(1.045);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    .icons {
        display: flex;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 99;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #fff;
            transition: 0.5s ease all;

            &:hover {
                background-color: #303030;

                .edit,
                .delete {
                    path {
                        fill: #fff;
                    }
                }
            }

            &:nth-child(1) {
                margin-right: 8px;
            }

            .edit,
            .delete {
                pointer-events: none;
                height: 15px;
                width: auto;
            }
        }
    }

    img {
        display: block;
        border-radius: 4px 4px 0 0;
        z-index: 1;
        width: 100%;
        object-fit: cover;

        @media (max-width: 700px) {
            max-height: 550px;
        }
    }
}
</style>