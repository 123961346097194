<template>
    <div>
        <CollectionHeader :post="{collectionTitle: 'Bali', CollectionPath: 'Blogs'}" />
        <CollectionHeader :post="{collectionTitle: 'Hong Kong', CollectionPath: 'Home'}" />
        <CollectionHeader :post="{collectionTitle: 'Greece', CollectionPath: 'Blogs'}" />
    </div>
    <div class="all-prints container">
        <h2>You want to browse all the items?</h2>
        <router-link class="router-button" :to="{ name: 'AllPrints' }">
            See all the prints
            <Arrow class="arrow arrow-light" />
        </router-link>
    </div>
</template>


<script>
import CollectionHeader from '@/components/CollectionHeader.vue';
import Arrow from "../assets/Icons/arrow-right-light.svg";

export default {
    name: "Prints-Vue",
    components: {
        CollectionHeader, Arrow
    },
    mounted() {
        window.scrollTo(0, 0)
    },
}
</script>


<style lang="scss" scoped>
.all-prints {
    position: relative;
    z-index: 15;
    padding: 200px 0;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 18px;
        text-align: center;

        @media (min-width: 800px) {
            font-size: 22px;
        }
    }

    .router-button {
        display: flex;
        font-size: 16px;
        text-decoration: none;
    }
}
</style>