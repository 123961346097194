<template lang="">
  <div>
    
  </div>
</template>
<script>
export default {
  name: "haha-a",
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>
<style lang="">
  
</style>