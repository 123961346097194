<template>
    <div class="blogs">
        <div class="blog-card-wrap">
            <div class="container">
                <h3>Blog</h3>
                <div class="blog-cards">
                    <div class="toggle-edit">
                        <span>Toggle Editing Post</span>
                        <input type="checkbox" v-model="editPost">
                    </div>
                    <div v-for="(iteratedPost, index) in blogPostsRecent" :key="index">
                        <BlogCard :post="iteratedPost"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="updates">
            <div class="container">
                <div class="update-text">
                    <h2>Never miss a news.</h2>
                    <h3>Register for the Newsletter and get a 15% discount valid for 48 hours!</h3>
                </div>
                <router-link class="router-button" to="#">
                    Subscribe to the Newsletter
                    <Arrow class="arrow arrow-light" />
                </router-link>
            </div>
        </div>

        <div class="blog-card-wrap">
            <div class="container">
                <h3>Previous posts</h3>
                <div class="all-blog-cards">
                    <div v-for="(iteratedPost, index) in blogPostsCards" :key="index">
                        <BlogCard :post="iteratedPost"/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import BlogCard from '@/components/BlogCard.vue';
import Arrow from "../assets/Icons/arrow-right-light.svg";
export default {
    name: "blogs-vue",
    components: { BlogCard, Arrow },
    mounted() {
        window.scrollTo(0, 0)
    },
    computed: {
        blogPostsRecent() {
            return this.$store.getters.blogPostsRecent;
        },
        blogPostsCards() {
            return this.$store.getters.blogPostsCards;
        },
        editPost: {
            get() {
                return this.$store.state.editPost
            },
            set(payload) {
                this.$store.commit("toggleEditPost", payload)

            },
        },
    },
    beforeUnmount() {
        this.$store.commit("toggleEditPost", false);
    },
    methods: {
        compare(blogCard1, blogCard2) {
            if ((new Date(blogCard1.date).getTime()) > (new Date(blogCard2.date).getTime())) {
                return -1;
            }
            if ((new Date(blogCard1.date).getTime()) < (new Date(blogCard2.date).getTime())) {
                return 1;
            }
            return 0;
        },
        sortedBlogCards() {
            return this.$store.state.sampleBlogCards.sort(this.compare);
        }
    }
};
</script>

<style lang="scss" scoped>
.blog-card-wrap {
    h3 {
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 32px;
        display: flex;
        z-index: 15;
        position: center;
        justify-content: center;
        align-items: center;
        padding: 0 0 20px 0px;
    }
}

.testest {
    background-color: #303030;
}

.blog-cards {
    position: relative;

    .toggle-edit {
        display: flex;
        align-items: center;
        position: absolute;
        top: -70px;
        right: 0;

        span {
            margin-right: 16px;
        }

        input[type="checkbox"] {
            position: relative;
            border: none;
            -webkit-appearance: none;
            background: #fff;
            outline: none;
            width: 80px;
            height: 30px;
            border-radius: 20px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        }

        input[type="checkbox"]:before {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 20px;
            top: 0;
            left: 0;
            background: #303030;
            transform: scale(1.1);
            transition: 750ms ease all;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        }

        input:checked[type="checkbox"]:before {
            background: #fff;
            left: 52px;
        }
    }
}

.updates {
    .container {
        padding: 50px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(min-width: 800px) {
            padding: 40px 25px;
            flex-direction: row;
        }

        .router-button {
            display: flex;
            font-size: 14px;
            text-decoration: none;

            @media(min-width: 800px) {
                margin-left: auto;
            }
        }

        .update-text {
            text-align: center;
            max-width: 500px;
            width: 100%;

            h2 {
                font-weight: 300;
                font-size: 32px;
                text-transform: uppercase;

                @media(min-width: 800px) {
                    text-align: initial;
                    font-size: 40px;
                }
            }

            h3 {
                font-weight: 150;
                font-size: 20px;

                @media(min-width: 800px) {
                    text-align: initial;
                    font-size: 25px;
                }
            }
        }
    }
}
</style>