<template>
  <div class="home">
    <div class="hero-section"></div>

    <div class="collection-wrap">
      <div class="container">
        <h3>Latest Collections</h3>
        <div class="collections">
          <Collection :post="iteratedPost" v-for="(iteratedPost, index) in sampleCollections" :key="index" />
        </div>
        <div class="all-collections">
          <router-link class="link" :to="{ name: 'Prints' }">
            View All Collections
            <Arrow class="arrow" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="updates">
      <div class="container">
        <div class="update-text">
          <h2>Never miss a news.</h2>
          <h3>
            Register for the Newsletter and get a 10% discount valid for 48
            hours!
          </h3>
        </div>
        <router-link class="router-button" :to="{ name: 'Login' }">
          Register for AKPA
          <Arrow class="arrow arrow-light" />
        </router-link>
      </div>
    </div>

    <div class="slider-title">
      <h3>My selection</h3>
    </div>
    <PrintsSlider />

    <div class="slider-title">
      <h3>Recent News</h3>
    </div>
    <BlogsSlider />
    <div class="slider-title">
      <div class="all-collections">
        <router-link class="link" :to="{ name: 'Blogs' }">
          View All News
          <Arrow class="arrow" />
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import Arrow from "../assets/Icons/arrow-right-light.svg";
import Collection from "@/components/Collection.vue";
import PrintsSlider from "@/components/PrintsSlider.vue";
import BlogsSlider from "@/components/BlogsSlider.vue";

export default {
  name: "HomeView",
  components: { Arrow, Collection, PrintsSlider, BlogsSlider },
  computed: {
    sampleCollections() {
      return this.$store.state.sampleCollections;
    },
    samplePrints() {
      return this.$store.state.samplePrints;
    },
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
  },
  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>

<style lang="scss" scoped>
.hero-section {
  scroll-snap-align: none;
  display: flex;
  justify-content: center;
  background-image: url(../assets/hero2.jpg);
  height: 115vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 100;
  margin-top: -145px;

  h2 {
    display: flex;
    text-align: center;
    font-size: 32px;
    font-weight: 100;
    height: 60vh;

    @media (min-width: 700px) {
      font-size: 40px;
    }

    .text-discount {
      color: #1eb8b8;
      white-space: pre;
    }

    .discount-description {
      white-space: pre;
    }
  }
}

.slider-title {
  h3 {
    font-weight: 300;
    font-size: 28px;
  }

  .all-collections {
    display: flex;
    position: center;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 60px 0;
    margin-top: -60px;

    .link {
      font-size: 15px;
      text-decoration: underline;
    }
  }
}

.blog-card-wrap,
.collection-wrap,
.print-wrap {
  position: relative;
  z-index: 15;

  h3 {
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 32px;
  }

  .all-collections {
    display: flex;
    position: center;
    justify-content: center;
    align-items: center;
    padding: 75px 0 0 0;

    .link {
      font-size: 15px;
      text-decoration: underline;
    }
  }
}

.blog-card-wrap {
  padding: 0 16px 80px;
}

.updates {
  position: relative;
  z-index: 15;

  .container {
    position: relative;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 800px) {
      padding: 60px 25px;
      flex-direction: row;
    }

    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;

      @media (min-width: 800px) {
        margin-left: auto;
      }
    }

    .update-text {
      text-align: center;
      max-width: 500px;
      width: 100%;

      h2 {
        font-weight: 300;
        font-size: 32px;
        text-transform: uppercase;

        @media (min-width: 800px) {
          text-align: initial;
          font-size: 40px;
        }
      }

      h3 {
        font-weight: 150;
        font-size: 20px;

        @media (min-width: 800px) {
          text-align: initial;
          font-size: 25px;
        }
      }
    }
  }
}
</style>