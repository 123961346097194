<template>
    <div class="home">
        <div class="hero-section"></div>
        <div class="text">
            <h2 class="content">Coming soon...</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "coming-soon-vue",
    mounted() {
        window.scrollTo(0, 0)
    },
}
</script>

<style lang="scss" scoped>
.home {
    position: relative;
}

.hero-section {
    scroll-snap-align: none;
    display: flex;
    justify-content: center;
    background-image: url(../assets/hero2.jpg);
    filter: grayscale(100%);
    height: 115vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 100;
    margin-top: -145px;
}

.text {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);

    .content {
        text-align: center;
        font-size: 35px;
        font-weight: 500;
        word-spacing: 42px;
        letter-spacing: 12px;
    }

    @media (min-width: 915px) {
        transform: translate(-49%, -50%);
        top: 20%;
        left: 50%;
    }
}
</style>