<template>
  <div
    class="collection"
    v-bind:class="post.released ? 'released' : 'not-released'"
  >
    <div v-show="editPost" class="icons">
      <div class="icon">
        <Edit class="edit" />
      </div>
      <div class="icon">
        <Delete class="delete" />
      </div>
    </div>

    <img
      :src="require(`../assets/collections/${post.collectionCoverPhoto}.png`)"
      alt=""
    />

    <video playsinline autoplay muted loop="cake.jpg" v-if="post.released">
      <source
        :src="require(`../assets/collections/${post.collectionCoverVideo}.mp4`)"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <h4 class="coming-soon" v-if="!post.released">Coming Soon</h4>

    <div class="info">
      <h4>{{ post.collectionTitle }}</h4>
    </div>
  </div>
</template>

<script>
import Edit from "../assets/Icons/edit-regular.svg";
import Delete from "../assets/Icons/trash-regular.svg";
export default {
  name: "CollectionComponent",
  props: ["post"],
  components: {
    Edit,
    Delete,
  },
  computed: {
    editPost() {
      return this.$store.state.editPost;
    },
  },
};
</script>

<style lang="scss" scoped>
.collection.collection.not-released {  
  @media (min-width: 960px) {
    &:hover {
      opacity: 0.8;
      img {
        filter: grayscale(100%);
      }
      .coming-soon {
        opacity: 1;
      }
    }
  }

  @media (max-width: 960px) {
    opacity: 0.8;
    img {
      filter: grayscale(100%);
    }
  }
}

.collection.collection.released {
  @media (min-width: 700px) {
    &:hover {
      video {
        z-index: 15;
        opacity: 1;
        transition: opacity 0.4s ease-out;
      }

      img {
        opacity: 0;
        transition: opacity 0.4s ease-out;
      }

      .info {
        opacity: 0;
        transition: opacity 0.3s ease-out;
      }
    }
  }
}

.collection {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  transition: 0.5s ease all;
  transition: height width none;
  border-radius: 12px 12px 12px 12px;

  align-items: center;

  height: 508px;
  width: 285px;

  @media (min-width: 700px) {
    &:hover {
      transform: scale(1.045);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }

  @media (max-width: 1199px) {
    min-height: 425px;
  }

  @media (min-width: 1500px) {
    height: 560px;
    width: 314px;

    min-height: 515px;
  }

  .icons {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.5s ease all;

      &:hover {
        background-color: #303030;

        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }

      &:nth-child(1) {
        margin-right: 8px;
      }

      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  img {
    z-index: 10;
    max-width: 315px;
  }

  video {
    z-index: 15;
    opacity: 0;
  }

  img,
  video {
    transition: 0.5s ease all;
    display: block;
    border-radius: 12px 12px 12px 12px;

    max-height: 508px;
    height: auto;
    width: auto;

    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;

    @media (min-width: 1500px) {
      max-height: 560px;
    }
  }

  .coming-soon {
    display: flex;
    flex-direction: row;
    z-index: 3;
    color: black;
    font-size: 22px;
    z-index: 20;

    opacity: 1;
    position: absolute;
    top: 25%;
    left: 27%;

    @media (min-width: 960px) {
      top: 26%;
      left: 50%;

      opacity: 0;
      transition: 0.5s ease;

      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }

  .info {
    color: #000;
    z-index: 20;

    position: absolute;
    top: 20%;
    /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    /* This is a shorthand of translateX(-50%) and translateY(-50%) */

    h4 {
      font-size: 20px;
      font-weight: 300;
      z-index: 20;
    }
  }
}
</style>