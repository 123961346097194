import firebase from "firebase/app";
import "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-JUtvQsadbmy5rVQf0QaMoV8ECqJyIIY",
  authDomain: "akpa-73ad0.firebaseapp.com",
  projectId: "akpa-73ad0",
  storageBucket: "akpa-73ad0.appspot.com",
  messagingSenderId: "447172045821",
  appId: "1:447172045821:web:2a6b5ef07869fe83574794",
  measurementId: "G-2MHFGYF9Z0"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimeStamp;
//const analytics = getAnalytics(firebaseApp)

export { timestamp };
export default firebaseApp.firestore();