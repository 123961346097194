import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import Vue2Editor from "vue2-editor";
import firebase from "firebase/app";
import "firebase/auth";

//Vue.use(Vue2Editor);

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    createApp(App).use(router).use(store).mount("#app");
  }
});