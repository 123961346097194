<template>

    <div class="swiper-wrap">
      <swiper :breakpoints="swiperOptions.breakpoints" :loop="true" :loopFillGroupWithBlank="true" :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }" :pagination="{
      clickable: true,
    }" :navigation="true" :modules="modules" class="print-swiper">
  
        <swiper-slide v-for="(iteratedPost, index) in samplePrints" :key="index" class="print-slide">
          <Print :post="iteratedPost" />
        </swiper-slide>
  
      </swiper>
    </div>
  
  </template>
  
  
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/pagination";
  import "swiper/css/navigation";

  import Print from "@/components/Print.vue";
  
  // import required modules
  import { Autoplay, Pagination, Navigation } from "swiper";
  
  export default {
    name: "PrintsSlider-Component",
    components: {
      Swiper,
      SwiperSlide,
      Print
    },
    data() {
      return {
        swiperOptions: {
          breakpoints: {
            1: {
              slidesPerView: 1,
              spaceBetween: 175
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 80
            },
            1000: {
              slidesPerView: 2,
              spaceBetween: 50
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 70
            },
          }
        }
      }
    },
    setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
      };
    },
    computed: {
      samplePrints() {
        return this.$store.state.samplePrints;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  /* Properties of the main swiper */
  .print-swiper {
    max-width: 1440px;
    min-width: 390px;
    width: 85%;
    height: 100%;
    padding: 0px 50px 60px 50px;
    z-index: 2000;
  }
  
  .print-slide {  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .print-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  </style>