<template>
  <div class="about-wrap">
    <div class="content">
      <div class="paragraph">
        <div class="text-first-child">
          <h3>Who am I?</h3>
          <p>
            My name is Awen and I am a Cybersecurity student at Ecole
            Polytechnique Fédérale de Lausanne (EPFL). Since I was a child I
            always had a curiosity for photography and capturing meaningful
            moments.
          </p>
          <p>
            In 2021, on the eve of my departure for my exchange year in Hong
            Kong, I decided to invest in a camera and a lense to give me the
            opportunity to document my journey. I immediately fell in love with
            the artistic process and I invested a lot of time and passion into
            my hobby.
          </p>
        </div>

        <img src="../assets/collections/stock-1.png" alt="" />
      </div>

      <div class="paragraph">
        <h3>Why selling prints?</h3>
        <p>
          During my artistic journey, I realised that having a printed picture
          using quality materials feels so much more rewarding than having a
          digital one. Thus, when I came back from Asia, I decided to use my
          programming knowledge to go one step further and sell my art to
          people. I try to convey emotions by sharing life-time moments with my
          pictures and selling prints is a way for me to achieve this goal. And
          for the people buying the print, is to have a beautiful piece of art
          at home.
        </p>
        <img class="landscape" src="../assets/hero2.jpg" alt="" />
      </div>

      <div class="paragraph">
        <h3>Type of print?</h3>
        <p>
          On my website I am selling a large amount of prints divided in
          collections. I have no stock as they are printed on demand. I made
          this choice to offer more choice to the customer. But it is also
          because I consider my website as a platform to display my art, and I
          want to show what I like.
        </p>
        <p>
          I am collaborating with ??? to provide quality prints. The material is
          ???.
        </p>
        <img class="landscape" src="../assets/hero2.jpg" alt="" />
        <p class="last-child">
          I printed at least once each picture I am selling on my website to
          make sure that each of them are qualitative.
        </p>
      </div>

      <div class="paragraph">
        <p>
          If you're not intersted by having a print, you can still show me
          support on my instagram @awen_jpg.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about-vue",
  components: {},
  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>

<style lang="scss" scoped>
.about-wrap {
  padding: 80px 16px;
  background-color: #f1f1f1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    padding: 85px 16px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .content {
    .paragraph {
      padding: 30px 0;
      text-align: start;
      line-height: 30px;
      max-width: 1300px;
      min-width: 100px;

      position: relative;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 22px;
      }

      p {
        font-size: 18px;
        padding: 6px 10px 20px 0px;
      }

      h3,
      p {
        max-width: 1100px;
      }

      .last-child {
        padding: 20px 10px 20px 0px;
      }

      .landscape {
        max-width: 750px;
        min-width: 250px;
        width: 100%;
        display: block;
        margin: auto;

      }

      img {
        border-radius: 8px 8px 8px 8px;
        overflow: hidden;
      }

      &:last-child {
        position: relative;
        display: flex;
        text-align: center;
        padding: 80px 0px 0px;

        p {
          font-size: 14px;
        }
      }

      &:first-child {
        text-align: justify;
        padding: 0px 0px 0px 0px;
        max-height: 800px;

        display: flex;
        flex-direction: column;
        justify-content: left;

        .text-first-child {
          max-width: 600px;
        }

        img {
          height: 100%;
          min-height: 300px;
          max-height: 500px;

          @media (min-width: 800px) {
            margin-left: 50px;
          }
        }

        @media (min-width: 1000px) {
          flex-direction: row;
        }
      }
    }
  }
}
</style>