import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ComingSoon from "../views/ComingSoon.vue";
import Blogs from "../views/Blogs.vue";
import About from "../views/About.vue";
import Prints from "../views/Prints.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Profile from "../views/Profile.vue";
import Admin from "../views/Admin.vue";
import Gallery from "../views/Gallery.vue";
import AllPrints from "../views/Prints/All-Prints.vue";
import CreatePost from "../views/Admin/CreatePost.vue";
import BlogPreview from "../views/Admin/BlogPreview.vue";
import EditBlog from "../views/Admin/EditBlog.vue";
import ViewBlog from "../views/Blogs/ViewBlog.vue";
//import firebase from "firebase/app";
//import "firebase/auth";

const routes = [
  {
    path: "/",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: 'Home',
      requiresAuth: false,
      uploaded: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: 'Home',
      requiresAuth: false,
    },
  },
  {
    path: "/prints",
    name: "Prints",
    component: Prints,
    meta: {
      title: 'Prints',
      requiresAuth: false,
    }
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    meta: {
      title: 'Blogs',
      requiresAuth: false,
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: 'About',
      requiresAuth: false,
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: 'Login',
      requiresAuth: false,
    }
  },
  {
    path: "/sell-print",
    name: "Sell Prints",
    component: Prints,
    meta: {
      title: 'Sell Prints',
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: "/create-post",
    name: "Create Post",
    component: CreatePost,
    meta: {
      title: 'Create Post',
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: 'Register',
      requiresAuth: false,
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: 'ForgotPassword',
      requiresAuth: false,
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: 'Profile',
      requiresAuth: true,
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: 'Admin',
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
    meta: {
      title: 'Gallery',
      requiresAuth: false,
    }
  },
  {
    path: "/all-prints",
    name: "AllPrints",
    component: AllPrints,
    meta: {
      title: 'All Prints',
      requiresAuth: false,
    }
  },
  {
    path: "/post-preview",
    name: "BlogPreview",
    component: BlogPreview,
    meta: {
      title: 'Preview Blog Post',
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: "/view-blog/:blogid",
    name: "ViewBlog",
    component: ViewBlog,
    meta: {
      title: 'View Blog Post',
      requiresAuth: false,
    }
  },
  {
    path: "/edit-blog/:blogid",
    name: "EditBlog",
    component: EditBlog,
    meta: {
      title: 'Edit Blog Post',
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Akpa`;
  next();
});


/*
router.beforeEach(async (to, from, next) => {
  let user = firebase.auth().currentUser;
  let admin = null;
  if (user) {
    let token = await user.getIdTokenResult();
    admin = token.claims.admin;
  }
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (admin) {
          return next();
        }
        return next({ name: "Home" });
      }
      return next();
    }
    return next({ name: "Home" });
  }
  return next();
});
*/

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((res) => !res.meta.uploaded)) {
    return next({ name: "ComingSoon" });
  }
  return next();
});

export default router;