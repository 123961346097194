<template>
    <div class="all-prints-page">

        <div class="filter" v-if="isFilter">
            <h3 class="filter-title">Shop by collection</h3>
            <div class="collection-filter">
                <div v-for="(collection, index) in collections" :key="index" class="filter-wrapper">
                    <input type="checkbox" :value="collection.collectionName" :id="collection.collectionName"
                        v-model="checkedCategories" @change="updateVisibleArticles">
                    <label :for="collection.collectionName" class="filter-name">{{ collection.collectionName
                    }}</label>
                </div>
            </div>
            <p class="reset-filters" @click="resetFilter">Reset filters</p>
        </div>

        <div class="all-prints-page-content">
            <div class="all-prints-header">
                <h2 class="title" v-if="!isMobile">Prints ({{this.filteredArticles.length}} showing)</h2>
                <h2 class="title" v-else>Prints ({{this.filteredArticles.length}})</h2>
                <div class="filter-header" @click="updateFilter">
                    <div v-if="!isMobile">
                        <p v-if="isFilter" class="filter-header-text">Hide filters</p>
                        <p v-else class="filter-header-text">Show filters</p>
                    </div>
                    <Filter class="icon" />
                </div>
            </div>

            <div class="all-prints">
                <div class="blog-card-wrap" v-if="filteredArticles.length > 0">
                    <pagination v-bind:items="filteredArticles" v-on:page:update="updatePage"
                        v-bind:currentPage="currentPage" v-bind:pageSize="pageSize">
                    </pagination>
                    <div class="container">
                        <div class="blog-cards">
                            <ArticlePreview :post="articles" v-for="articles in visibleArticles"
                                v-bind:visibleArticles="visibleArticles" v-bind:currentPage="currentPage"
                                v-bind:articles="articles" :key="articles.id">
                            </ArticlePreview>
                        </div>
                    </div>
                    <pagination v-bind:items="filteredArticles" v-on:page:update="updatePage"
                        v-bind:currentPage="currentPage" v-bind:pageSize="pageSize">
                    </pagination>
                </div>
                <div v-else class="no-item">
                    <p>No print found...</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/Print/Pagination.vue';
import ArticlePreview from '@/components/Print/Article-Preview.vue';
import Filter from "../../assets/Icons/filter.svg"

export default {
    name: 'all-prints-vue',
    data() {
        return {
            preview: [
                {
                    id: 0, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
                {
                    id: 1, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-2',
                    articleCollection: 'Vietnam'
                },
                {
                    id: 2, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-3',
                    articleCollection: 'Vietnam'
                },
                {
                    id: 3, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-4',
                    articleCollection: 'Bali'
                },
                {
                    id: 4, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-2',
                    articleCollection: 'Hong Kong'
                },
                {
                    id: 5, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-4',
                    articleCollection: 'Hong Kong'
                },
                {
                    id: 6, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
                {
                    id: 7, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-3',
                    articleCollection: 'Bali'
                },
                {
                    id: 8, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
                {
                    id: 9, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
                {
                    id: 10, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
                {
                    id: 11, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
                {
                    id: 12, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
                {
                    id: 13, articleTitle: 'Blog Card #1',
                    articlePhoto: 'stock-1',
                    articleCollection: 'Bali'
                },
            ],
            collections: [
                { collectionName: 'Bali' },
                { collectionName: 'Hong Kong' },
                { collectionName: 'Thailand' },
                { collectionName: 'Greece' },
                { collectionName: 'Vietnam' },
            ],
            isFilter: true,
            currentPage: 0,
            pageSize: 9,
            visibleArticles: [],
            filteredArticles: [],
            windowWidth: null,
            checkedCategories: [],
            isMobile: null,
        };
    },
    components: {
        Pagination,
        ArticlePreview,
        Filter,
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    beforeMount: function () {
        this.updateVisibleArticles();
    },
    created() {
        window.addEventListener("resize", this.checkScreen);
        this.checkScreen();
    },
    methods: {
        updatePage(pageNumber) {
            this.currentPage = pageNumber;
            this.updateVisibleArticles();
        },
        updateVisibleArticles() {
            this.filteredArticles = this.preview;
            if (this.checkedCategories.length > 0) {
                this.filteredArticles = this.preview.filter((e) => this.checkedCategories.includes(e.articleCollection));
            }
            this.visibleArticles = this.filteredArticles.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize);

            // if we have 0 visible article, go back a page
            if (this.visibleArticles.length == 0 && this.currentPage > 0) {
                this.updatePage(this.currentPage - 1);
            }
        },
        updatePageSize(newSize) {
            this.pageSize = newSize;
            this.updateVisibleArticles();
        },
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth > 700 && this.windowWidth <= 1400) {
                this.isMobile = false;
                this.updatePageSize(8);
                return;
            } else if (this.windowWidth <= 700) {
                this.isMobile = true;
                this.updatePageSize(4);
                return;
            } else {
                this.isMobile = false;
                this.updatePageSize(9);
                return;
            }
        },
        updateFilter() {
            this.isFilter = !this.isFilter;
        },
        resetFilter() {
            this.checkedCategories = [];
            this.updateVisibleArticles();
        }
    }
}

</script>


<style lang="scss" scoped>
.all-prints-page {
    display: flex;
    flex-direction: row;
}

.filter {
    padding: 10px 0;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-left: 20px;
    padding-bottom: 50px;
    padding-top: 50px;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    clip-path: inset(0px -4px 0px 0px);

    @media (min-width: 700px) {
        width: 450px;
        padding-left: 20px;
    }

    @media (min-width: 1050px) {
        width: 350px;
        padding-left: 30px;
    }

    .filter-title {
        font-size: 15px;
        font-weight: 550;

        @media (min-width: 800px) {
            font-size: 16px;
            font-weight: 550;
        }
    }

    .collection-filter {
        padding-top: 6px;
        padding-bottom: 4px;
        font-size: 13px;
        font-weight: 400;

        @media (min-width: 800px) {
            font-size: 14px;
            font-weight: 400;
        }

        .filter-wrapper {
            padding: 6px 0;

            @media (min-width: 800px) {
                padding-left: 15px;
            }

            .filter-name {
                padding-left: 4px;
            }
        }
    }

    .reset-filters {
        cursor: pointer;
        font-size: 13px;
        font-weight: 450;
        text-decoration: underline;

        @media (min-width: 800px) {
            font-size: 13px;
            font-weight: 550;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.all-prints-page-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    .all-prints-header {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        min-height: 100px;

        .title {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 50px 0;
            font-size: 18px;
            font-weight: 600;

            @media (min-width: 800px) {
                font-size: 20px;
                font-weight: 650;
            }
        }

        .filter-header {
            position: absolute;
            right: 5%;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            cursor: pointer;

            p {
                padding-right: 4px;
            }
        }
    }
}

.all-prints {
    text-align: center;
}

.blog-card-wrap {
    padding: 0px 50px;
}

.no-item {
    font-size: 15px;
    font-weight: 450;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>