import Vuex from 'vuex'
import firebase from "firebase/app";
import "firebase/auth";
import db from "../firebase/firebaseInit";

export default Vuex.createStore({
  state: {
    blogPosts: [],
    postLoaded: null,
    blogHTML: "Write your blog title here...",
    blogTitle: "",
    blogPhotoName: "",
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    editPost: null,
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,
    navigationDisabled: null,
    isHomePage: null,
    /*
    sampleBlogCards: [
      { blogTitle: "Blog Card #1", blogCoverPhoto: "stock-1", blogDate: "May 9, 2021", date: "05-09-2021" },
      { blogTitle: "Blog Card #2", blogCoverPhoto: "stock-2", blogDate: "May 2, 2021", date: "05-02-2021" },
      { blogTitle: "Blog Card #3", blogCoverPhoto: "stock-3", blogDate: "May 3, 2021", date: "05-03-2021" },
      { blogTitle: "Blog Card #4", blogCoverPhoto: "stock-4", blogDate: "May 18, 2021", date: "05-18-2021" },
      { blogTitle: "Blog Card #5", blogCoverPhoto: "stock-4", blogDate: "May 2, 2021", date: "05-02-2021" },
      { blogTitle: "Blog Card #6", blogCoverPhoto: "stock-4", blogDate: "May 7, 2021", date: "05-07-2021" },
      { blogTitle: "Blog Card #18", blogCoverPhoto: "stock-4", blogDate: "June 2, 2021", date: "06-02-2021" },
      { blogTitle: "Blog Card #5", blogCoverPhoto: "stock-4", blogDate: "May 4, 2021", date: "05-04-2021" },
],
    */
    sampleCollections: [
      { collectionTitle: "Bali", collectionCoverPhoto: "stock-11", released: true, collectionCoverVideo: "video-1" },
      { collectionTitle: "Hong Kong", collectionCoverPhoto: "stock-22", released: true, collectionCoverVideo: "video-2" },
      { collectionTitle: "Vietnam", collectionCoverPhoto: "stock-33", released: true, collectionCoverVideo: "video-3" },
      { collectionTitle: "Greece", collectionCoverPhoto: "stock-44", released: false, collectionCoverVideo: "" },
    ],
    samplePrints: [
      { printCollection: "Bali", printCoverPhoto: "stock-1" },
      { printCollection: "Hong Kong", printCoverPhoto: "stock-2" },
      { printCollection: "Paris", printCoverPhoto: "stock-3" },
      { printCollection: "Vietnam", printCoverPhoto: "stock-4" },
    ],
  },
  getters: {
    blogPostsRecent(state) {
      return state.blogPosts.slice(0, 4);
    },
    blogPostsCards(state) {
      return state.blogPosts.slice(4);
    },
  },
  mutations: {
    newBlogPost(state, payload) {
      state.blogHTML = payload;
    },
    updateBlogTitle(state, payload) {
      state.blogTitle = payload;
    },
    fileNameChange(state, payload) {
      state.blogPhotoName = payload;
    },
    createFileURL(state, payload) {
      state.blogPhotoFileURL = payload;
    },
    openPhotoPreview(state) {
      state.blogPhotoPreview = !state.blogPhotoPreview;
    },
    toggleEditPost(state, payload) {
      state.editPost = payload;
    },
    setBlogState(state, payload) {
      state.blogTitle = payload.blogTitle;
      state.blogHTML = payload.blogHTML;
      state.blogPhotoFileURL = payload.blogCoverPhoto;
      state.blogPhotoName = payload.blogCoverPhotoName;
    },
    filterBlogPost(state, payload) {
      state.blogPosts = state.blogPosts.filter((post) => post.blogID !== payload);
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    setProfileAdmin(state, payload) {
      state.profileAdmin = payload;
      console.log(state.profileAdmin);
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      state.profileEmail = doc.data().email;
      state.profileFirstName = doc.data().firstName;
      state.profileLastName = doc.data().lastName;
      state.profileUsername = doc.data().username;
      console.log(state.profileId);
      console.log(state.profileEmail);
    },
    setProfileInitials(state) {
      state.profileInitials =
        state.profileFirstName.match(/(\b\S)?/g).join("") + state.profileLastName.match(/(\b\S)?/g).join("");
    },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
    },
    changeUsername(state, payload) {
      state.profileUsername = payload;
    },
  },
  actions: {
    // async getCurrentUser({ commit }, user) {
    async getCurrentUser({ commit }, user) {
      //reaching out to our collection 
      //and get the collection that has the current user ID that is logged in to our app
      const dataBase = await db.collection("users").doc(firebase.auth().currentUser.uid);
      const dbResults = await dataBase.get();
      commit("setProfileInfo", dbResults);
      commit("setProfileInitials");
      const token = await user.getIdTokenResult();
      const admin = await token.claims.admin;
      commit("setProfileAdmin", admin);
    },
    async updateUserSettings({ commit, state }) {
      const dataBase = await db.collection("users").doc(state.profileId);
      await dataBase.update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        username: state.profileUsername,
      });
      commit("setProfileInitials");
    },
    async updatePost({ commit, dispatch }, payload) {
      commit("filterBlogPost", payload);
      await dispatch("getPost");
    },
    async getPost({ state }) {
      const dataBase = await db.collection("blogPosts").orderBy("date", "desc");
      const dbResults = await dataBase.get(); 
      dbResults.forEach((doc) => {
        if (!state.blogPosts.some((post) => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
          };
          state.blogPosts.push(data);
        }
      });
      state.postLoaded = true;
      console.log(state.blogPosts);
    },
    async deletePost({ commit }, payload) {
      const getPost = await db.collection("blogPosts").doc(payload);
      await getPost.delete();
      commit("filterBlogPost", payload);
    },
  },
  modules: {
  }
});
