<template>
  <div class="print">
    <div v-show="editPost" class="icons">
      <div class="icon">
        <Edit class="edit" />
      </div>
      <div class="icon">
        <Delete class="delete" />
      </div>
    </div>

    <img :src="require(`../assets/collections/${post.printCoverPhoto}.png`)" alt="" />
    <div class="info" v-if="true">
      <h4>{{ post.printCollection }}</h4>
    </div>
  </div>
</template>

<script>
import Edit from "../assets/Icons/edit-regular.svg";
import Delete from "../assets/Icons/trash-regular.svg";
export default {
  name: "PrintComponent",
  props: ["post"],
  components: {
    Edit,
    Delete,
  },
  computed: {
    editPost() {
      return this.$store.state.editPost;
    },
  },
};
</script>

<style lang="scss" scoped>
.print {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  transition: 0.5s ease all;

  width: 290px;
  height: 530px;

  @media (min-width: 700px) {
    &:hover {
      transform: scale(1.045);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }

  @media (min-width: 1500px) {
    width: 330px;
    height: 580px;
  }

  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    position: center;
    align-items: center;
    padding: 6px 0;
    color: #000;

    h4 {
      padding-bottom: 8px;
      font-size: 14px;
      font-weight: 200;
    }
  }

  .icons {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.5s ease all;

      &:hover {
        background-color: #303030;

        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }

      &:nth-child(1) {
        margin-right: 8px;
      }

      .edit,
      .delete {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  img {
    transition: 0.5s ease all;
    display: block;
    border-radius: 6px 6px 6px 6px;
    z-index: 1;

    min-width: 290px;
    min-height: 500px;
    width: auto;
    height: auto;

    object-fit: cover;

    @media (min-width: 1500px) {
      min-width: 330px;
      min-height: 550px;
    }
  }
}
</style>