<template>
    <div>
        <div v-if="totalPages() > 0" class="pagination-wrapper">
            <span v-if="showPreviousLink()" class="pagination-btn" v-on:click="updatePage(currentPage - 1)"> Previous
            </span>
            {{ currentPage + 1 }} of {{ totalPages() }}
            <span v-if="showNextLink()" class="pagination-btn" v-on:click="updatePage(currentPage + 1)"> Next </span>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'pagination-component',
    props: ['items', 'currentPage', 'pageSize'],
    methods: {
        updatePage(pageNumber) {
            this.$emit('page:update', pageNumber);
            window.scrollTo(0, 0);
        },
        totalPages() {
            return Math.ceil(this.items.length / this.pageSize);
        },
        showPreviousLink() {
            return this.currentPage == 0 ? false : true;
        },
        showNextLink() {
            return this.currentPage == (this.totalPages() - 1) ? false : true;
        }
    }
}

</script>

<style lang="scss" scoped>
.pagination-btn {
    cursor: pointer;
    text-decoration: underline;
}

.pagination-wrapper {
    padding: 40px 0 40px 0;
    font-size: 14px;
}
</style>