<template>
    <div class="collection-header">
        <router-link class="link" :to="{ name: post.CollectionPath }">
            <img src="../assets/hero-banner.png" />
            <h2> {{ post.collectionTitle }} </h2>
        </router-link>
    </div>
</template>
<script>
export default {
    name: "CollectionHeader-Component",
    props: ["post"],
}
</script>


<style lang="scss" scoped>
.collection-header {
    position: relative;

    img {
        display: flex;
        max-width: 100%;
        min-height: 300px;
        object-fit: cover;
    }

    h2 {
        font-weight: 375;
        font-size: 35px;
        display: flex;
        position: absolute;
        top: 35%;
        left: 10%;
        text-transform: none;
        //transform: translate(-50%, -50%);

        @media (max-width: 750px) {
            left: 6%;
            font-size: 28px;
        }
    }

    &:hover {
        img {
            filter: blur(0.5px) brightness(0.95);
            opacity: 0.8;
            transition: 0.3s ease all;
        }

        h2 {
            opacity: 1;
        }
    }

    &:first-child {
        margin-top: -90px;
    }
}
</style>