<template>

  <div class="swiper-wrap">
    <swiper :breakpoints="swiperOptions.breakpoints" :loop="true" :loopFillGroupWithBlank="false" :pagination="{
      clickable: true,
    }" :navigation="true" :modules="modules" class="blog-swiper">

      <template v-for="(iteratedPost, index) in blogPostsRecent" :key="index" >
        <swiper-slide v-if="index < 4" class="blog-slide">
          <BlogCard :post="iteratedPost"/>
        </swiper-slide>
      </template>

    </swiper>
  </div>

</template>
  
  
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import BlogCard from "@/components/BlogCard.vue";

// import required modules
import { Pagination, Navigation } from "swiper";

export default {
  name: "PrintsSlider-Component",
  components: {
    Swiper,
    SwiperSlide,
    BlogCard
  },
  data() {
    return {
      swiperOptions: {
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetween: 175
          },
          500: {
            slidesPerView: 1,
            spaceBetween: 80
          },
          880: {
            slidesPerView: 2,
            spaceBetween: 70
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 70
          },
        }
      }
    }
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  computed: {
    blogPostsRecent() {
      return this.$store.getters.blogPostsRecent;
    },
  },
};
</script>
  
<style lang="scss" scoped>
/* Properties of the main swiper */
.blog-swiper {
  min-width: 390px;
  width: 85%;
  height: 100%;
  padding: 0px 60px 60px 60px;
}

.blog-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.blog-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>